<template>
    <div class="navbar-container d-flex content align-items-center">
        <!-- Nav Menu Toggler -->
        <ul class="nav navbar-nav d-xl-none">
            <li class="nav-item">
                <b-link class="nav-link" @click="toggleVerticalMenuActive">
                    <feather-icon icon="MenuIcon" size="21"/>
                </b-link>
            </li>
        </ul>

        <!-- Left Col -->
        <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
            <locale />
            <dark-Toggler class="d-none d-lg-block" />
        </div>

        <b-navbar-nav class="nav align-items-center ml-auto">
            <b-nav-item-dropdown
                class="dropdown-user"
                right
                toggle-class="d-flex align-items-center dropdown-user-link"
            >
                <template #button-content>
                    <div class="d-sm-flex d-none user-nav">
                        <p class="user-name font-weight-bolder">
                            {{ current.name }}
                        </p>
                        <span v-if="current.role" class="user-status">{{ $t(current.role.name) }}</span>
                    </div>
                    <b-avatar
                        :src="current.avatar"
                        badge
                        badge-variant="success"
                        class="badge-minimal"
                        size="40"
                        variant="light-primary"
                    />
                </template>

                <b-dropdown-item link-class="d-flex align-items-center">
                    <feather-icon class="mr-50" icon="UserIcon" size="16"/>
                    <span>{{ $t('Profile') }}</span>
                </b-dropdown-item>

                <b-dropdown-divider />

                <b-dropdown-item @click="logout" link-class="d-flex align-items-center">
                    <feather-icon class="mr-50" icon="LogOutIcon" size="16"/>
                    <span>{{ $t('logout') }}</span>
                </b-dropdown-item>
            </b-nav-item-dropdown>
        </b-navbar-nav>
    </div>
</template>

<script>
import {
    BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import Locale from '@core/layouts/components/app-navbar/components/Locale.vue'
import { mapActions, mapGetters } from "vuex";

export default {
    components: {
        BLink,
        BNavbarNav,
        BNavItemDropdown,
        BDropdownItem,
        BDropdownDivider,
        BAvatar,

        // Navbar Components
        DarkToggler,
        Locale
    },
    props: {
        toggleVerticalMenuActive: {
            type: Function,
            default: () => {
            },
        },
    },

    computed:{
        ...mapGetters('user',[
            'current'
        ])
    },
    methods:{
        ...mapActions({
            logout: 'auth/logout'
        })
    }
}
</script>
