export default [
    {
        header: 'nav.headers.main',
    },
    {
        title: 'nav.dashboard',
        route: 'dashboard',
        icon: 'HomeIcon',
    },
    {
        header: 'nav.headers.blog',
    },
    {
        title: 'nav.posts',
        route: 'posts',
        icon: 'HomeIcon',
        action: 'post-view'

    },
    {
        title: 'nav.comments',
        route: 'comments',
        icon: 'MessageCircleIcon',
        tag: '',
        tagVariant: 'warning',
    },
    {
        title: 'nav.actors',
        route: 'actors',
        icon: 'UserIcon',
        action: 'actor-view'

    },
    {
        title: "nav.category",
        route: 'category',
        icon: 'LayersIcon',
        action: 'category-view'

    },
    {
        title: "Genre",
        route: 'genres',
        icon: 'LayersIcon',
        action: 'genre-view'
    },
    {
        title: "nav.file_manager",
        route: 'file-manager',
        target: '_blank',
        icon: 'FolderIcon',
    },
    {
        title: "nav.users",
        route: 'users',
        icon: 'UsersIcon',
        action: 'user-view'

    },
    {
        title: "nav.redirects",
        route: 'redirects',
        icon: 'LinkIcon',
    },
]
