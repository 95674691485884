<template>
    <b-nav-item-dropdown
        id="dropdown-grouped"
        class="dropdown-language"
        right
        variant="link"
    >
        <template #button-content>
            <b-img
                :alt="currentLocale.locale"
                :src="currentLocale.img"
                height="14px"
                width="22px"
            />
            <span class="ml-50 text-body">{{ currentLocale.name }}</span>
        </template>
        <b-dropdown-item
            v-for="localeObj in locales"
            :key="localeObj.locale"
            @click="changeLocale(localeObj)"
        >
            <b-img
                :alt="localeObj.locale"
                :src="localeObj.img"
                height="14px"
                width="22px"
            />
            <span class="ml-50">{{ localeObj.name }}</span>
        </b-dropdown-item>
    </b-nav-item-dropdown>
</template>

<script>
import { BNavItemDropdown, BDropdownItem, BImg } from 'bootstrap-vue'
import { localize } from "vee-validate";

export default {
    components: {
        BNavItemDropdown,
        BDropdownItem,
        BImg,
    },
    // ! Need to move this computed property to comp function once we get to Vue 3
    computed: {
        currentLocale() {
            return this.locales.find(l => l.locale === this.$i18n.locale)
        },
    },
    setup() {
        /* eslint-disable global-require */
        const locales = [
            {
                locale: 'fa',
                img: require('@/assets/images/flags/fa.png'),
                name: 'فارسی',
                rtl: true
            },
            {
                locale: 'en',
                img: require('@/assets/images/flags/en.png'),
                name: 'English',
                rtl: false
            },
        ]
        /* eslint-disable global-require */

        return {
            locales,
        }
    },
    methods: {
        changeLocale(localeObj) {
            this.$store.commit('appConfig/SET_LOCALE', localeObj)
        }
    }
}
</script>

<style>

</style>
